const prod = process.env.NODE_ENV === 'production';

export default {
  siteName: 'Local Solicitor',
  siteIcon: 'ion-flash',
  footerText: `© ${new Date().getFullYear()} test`,
  wentWrongTxt: 'Something went wrong. Try later',
  enableAnimatedRoute: false,

  // local
  // apiUrl: 'http://192.168.0.162:1337/api/',

  // teamgroovy domain
  // apiUrl: 'http://localsolicitors.teamgroovy.com:1337/api/',
  // siteUrl: 'http://localsolicitors.teamgroovy.com',
  // csvUrl: 'http://localsolicitors.teamgroovy.com:1337/SampleCsv',
  // domainUrl: prod
  //   ? 'http://localsolicitors.teamgroovy.com'
  //   : 'http://localhost:3000',
  // sailsUrl: 'http://localsolicitors.teamgroovy.com',

  // // client domain
  apiUrl: 'https://api.localsolicitors.com/api/',
  siteUrl: 'https://www.localsolicitors.com',
  csvUrl: 'https://api.localsolicitors.com/SampleCsv',
  domainUrl: prod ? 'https://www.localsolicitors.com' : 'http://localhost:3000',
  sailsUrl: 'https://www.localsolicitors.com',

  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  tinyEditorKey: '600vmwvuedrf0pom7f5e98wykltyiqnsil6cx3w2vfm3v7tu',
  dashboard: '/dashboard',
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  postcodeApiKey: process.env.REACT_APP_POSTCODE_API_KEY,
  paypalClientId: '',
};
